import { AnalyticsCalendarModal } from "@/components/analytics/AnalyticsCalendarModal/AnalyticsCalendarModal";
import { Navbar } from "@/components/general/Navbar/Navbar";
import { Footer } from "@/components/marketing/Footer";
import { Chart } from "@/components/shared/chart/chart";
import { ShouldLoginModal } from "@/components/should-login-modal/should-login-modal";
import { FilterBar } from "@/components/spread/FilterBar/FilterBar";
import { LeaderboardWebsites } from "@/components/spread/LeaderboardWebsites/LeaderboardWebsites";
import { SpreadFetchOnMount } from "@/components/spread/SpreadFetchOnMount/SpreadFetchOnMount";
import { Container } from "@/components/ui/Container";
import { RouteComponentProps } from "@reach/router";
import clsx from "clsx";
import React from "react";

export const HomeRoute: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Navbar />
      <Container id="demo">
        <div className={clsx("relative py-2")}>
          <FilterBar />

          <div className="mt-2" />
          <Chart view="global" />
          <div className="mt-2" />
          <LeaderboardWebsites />
        </div>

        <SpreadFetchOnMount />
        <AnalyticsCalendarModal />
      </Container>

      <ShouldLoginModal />
      <Footer />
    </>
  );
};
