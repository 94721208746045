import { ErrorEntity, UserEntity } from "@my-search-console/interfaces";
import delay from "delay";
import { ThunkAction } from "redux-thunk";

import { MODAL_KEYS } from "@/entities/ModalEntity";
import {
  getCallbackUrl,
  getCallbackUrlAddMoreSearchConsoles,
} from "../../constants/authentication";
import { localStorageKeys } from "../../constants/localStorageKeys";
import { GetUserInfoResponse } from "../../interfaces/IAuthRepository";
import { normalizeUrl } from "../../utils/normalizeUrl";
import { actions } from "../actions";
import { RootState } from "../store";
import * as types from "./types";

export const storeUser = (
  payload: types.storeUserAction["payload"]
): types.AuthActionTypes => ({
  type: types.storeUser,
  payload,
});

export const setFetching = (
  payload: types.setFetchingAction["payload"]
): types.AuthActionTypes => ({
  type: types.setFetching,
  payload,
});

export const AuthSetInitialized = (
  payload: types.AuthSetInitializedAction["payload"]
): types.AuthActionTypes => ({
  type: types.AuthSetInitialized,
  payload,
});

export const AuthLogout = (): types.AuthActionTypes => ({
  type: types.AuthLogout,
});

export const AuthStoreSources = (
  payload: types.AuthStoreSourcesAction["payload"]
): types.AuthActionTypes => ({
  type: types.AuthStoreSources,
  payload,
});

export const $request_login_if_not_authenticated =
  (
    callback: (user: UserEntity) => void
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { auth } = getState();

    if (!auth.user) {
      return dispatcher(
        actions.modals.$open({ key: MODAL_KEYS["should-login"] })
      );
    } else {
      return callback(auth.user);
    }
  };

const $getUserInfoAndStoreIt =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    try {
      const user = await di.AuthRepository.getUserInfo();

      if (user.error === false) {
        dispatcher(storeUser(user.body));
      }

      return user;
    } catch (e) {}
  };

export const $logout =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState();

    di.AnalyticsService.send({
      action: "logout",
      category: "authentication",
    });
    dispatcher(actions.auth.AuthLogout());
    di.AnalyticsService.logout();
    di.LocalStorageService.remove(localStorageKeys.TOKEN_KEY);
    di.LocationService.navigate(normalizeUrl({ url: "/", locale: lang.lang }));
  };

const getLanguageFromNavigator = () => {
  if (!navigator) return "en";
  if (!navigator.language) return "en";
  return navigator.language?.split("-")[0] || "en";
};

export const $authenticate =
  (params?: { redirection?: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState();

    if (params?.redirection) {
      di.LocalStorageService.store(
        localStorageKeys.REDIRECT_URL_AFTER_LOGIN,
        params.redirection
      );
    }

    di.AnalyticsService.send({
      category: "authentication",
      action: "trying_to_connect",
      data: {
        state: "go_to_authentication",
      },
    });

    await delay(500);

    const getGoogleAuthUrl = await di.AuthRepository.getAuthenticationUrl(
      "google"
    );

    if (getGoogleAuthUrl.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: getGoogleAuthUrl.code,
        })
      );
    }

    di.LocationService.navigate(getGoogleAuthUrl.body);
  };

export const $authenticateWithGoogleCode =
  (params: {
    code: string;
    redirect?: string;
  }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState();

    const response = await di.AuthRepository.postAuthenticationCode({
      language: getLanguageFromNavigator(),
      callbackUrl: getCallbackUrl("google"),
      code: params.code,
      type: "google",
    });

    if (
      response.error === true &&
      response.code === ErrorEntity.GOOGLE_AUTH_SCOPE_NOT_FOUND
    ) {
      di.AnalyticsService.send({
        category: "authentication",
        action: "trying_to_connect",
        data: {
          state: "scope-not-found",
        },
      });

      return di.LocationService.refresh(
        "/authentication/google/scope-not-found"
      );
    }

    if (response.error === true) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    di.LocalStorageService.store(localStorageKeys.TOKEN_KEY, response.body);

    const user: GetUserInfoResponse = await dispatcher<any>(
      $getUserInfoAndStoreIt()
    );

    if (user.error === true) {
      dispatcher(
        actions.notifications.create({
          type: "error",
          message: user.code,
        })
      );
    } else {
      di.AnalyticsService.send({
        action: "login",
        category: "authentication",
      });

      di.AnalyticsService.authenticate({
        id: user.body.id,
        created_at: user.body.created_at,
      });
    }

    if (di.LocalStorageService.get(localStorageKeys.REDIRECT_URL_AFTER_LOGIN)) {
      di.LocationService.navigate(
        `/redirect${di.LocalStorageService.get(
          localStorageKeys.REDIRECT_URL_AFTER_LOGIN
        )}`
      );

      di.LocalStorageService.remove(localStorageKeys.REDIRECT_URL_AFTER_LOGIN);
    } else {
      di.LocationService.refresh("/");
    }
  };

export const $isAuthenticated =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(setFetching({ value: true }));

    await dispatcher<any>($getUserInfoAndStoreIt());

    dispatcher(actions.auth.AuthSetInitialized({ value: true }));
    dispatcher(setFetching({ value: false }));
  };

export const $isAuthenticatedOrRedirect =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    await dispatcher(actions.auth.$isAuthenticated());

    const { auth, di, lang } = getState();

    if (auth.authenticated) return;

    di.LocationService.navigate(normalizeUrl({ url: "/", locale: lang.lang }));
  };

export const $SearchConsolesAddGoToUrl =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    const getGoogleAuthUrl = await di.AuthRepository.getAuthenticationUrl(
      "google",
      getCallbackUrlAddMoreSearchConsoles()
    );

    if (getGoogleAuthUrl.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: getGoogleAuthUrl.code,
        })
      );
    }

    di.LocationService.navigate(getGoogleAuthUrl.body);
  };

export const AuthSetGoogleSearchConsoles = (
  payload: types.AuthSetGoogleSearchConsolesAction["payload"]
): types.AuthActionTypes => ({
  type: types.AuthSetGoogleSearchConsoles,
  payload,
});

export const $google_open_add_accounts_modal =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(
      actions.auth.$request_login_if_not_authenticated(() => {
        dispatcher(
          actions.modals.$open({ key: MODAL_KEYS["add-more-search-consoles"] })
        );
      })
    );
  };

export const $SearchConsolesFetch =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    const response = await di.AuthRepository.getGoogleSearchAccounts();

    if (response.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    dispatcher(AuthSetGoogleSearchConsoles(response.body));
  };

export const auth_bing_store_accounts_action = (
  payload: types.auth_bing_store_accounts_action["payload"]
): types.AuthActionTypes => ({
  type: types.auth_bing_store_accounts,
  payload,
});

export const auth_bing_accounts_fetching_action = (
  payload: types.auth_bing_accounts_fetching_action["payload"]
): types.AuthActionTypes => ({
  type: types.auth_bing_accounts_fetching,
  payload,
});

export const $bing_accounts_fetch =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    dispatcher(auth_bing_accounts_fetching_action({ value: true }));

    const response = await di.AuthRepository.get_bing_accounts();

    dispatcher(auth_bing_accounts_fetching_action({ value: false }));

    if (response.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    dispatcher(actions.auth.auth_bing_store_accounts_action(response.body));
  };

export const $bing_open_add_modal =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(
      actions.auth.$request_login_if_not_authenticated(() => {
        dispatcher(
          actions.modals.$open({ key: MODAL_KEYS["bing-add-accounts"] })
        );
      })
    );
  };

export const $SearchConsolesDelete =
  (params: { id: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    const response = await di.AuthRepository.deleteGoogleSearchAccount(
      params.id
    );

    if (response.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    dispatcher($SearchConsolesFetch());
    dispatcher(actions.spread.$fetch());
  };

export const $SearchConsolesAddWithCallbackCode =
  (params: { code: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState();

    const response =
      await di.AuthRepository.postAuthenticationCodeAddMoreSearchConsoles({
        language: getLanguageFromNavigator(),
        callbackUrl: getCallbackUrlAddMoreSearchConsoles(),
        code: params.code,
      });

    // @todo scope not found
    if (
      response.error === true &&
      response.code === ErrorEntity.GOOGLE_AUTH_SCOPE_NOT_FOUND
    ) {
      di.AnalyticsService.send({
        category: "authentication",
        action: "trying_to_connect",
        data: {
          state: "scope-not-found",
        },
      });

      return di.LocationService.refresh(
        "/authentication/google/scope-not-found"
      );
    }

    if (response.error === true) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    di.LocationService.navigate("/?add-more-search-consoles=true");
  };

export const $BingWebmasterAccountsAddGoToUrl =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState();

    const response = await di.AuthRepository.getAuthenticationUrl("bing");

    if (response.error) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    di.LocationService.navigate(response.body);
  };

export const $BingWebmasterAccountsSendCallbackCode =
  (params: { code: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, lang } = getState();

    const response = await di.AuthRepository.postAuthenticationCode({
      language: getLanguageFromNavigator(),
      callbackUrl: getCallbackUrl("bing"),
      code: params.code,
      type: "bing",
    });

    if (response.error === true) {
      return dispatcher(
        actions.notifications.create({
          type: "error",
          message: response.code,
        })
      );
    }

    console.log(response.body);
  };
