import { RouteComponentProps } from "@reach/router";
import React from "react";

import { BingAuthCallbackPage } from "@/components/bing-auth-callback-page/bing-auth-callback-page";

const AuthenticationBingCallbackRoute: React.FC<RouteComponentProps> = (
  props
) => {
  return <BingAuthCallbackPage />;
};

export default AuthenticationBingCallbackRoute;
