import { getCrispUrl } from "@/utils/crisp";
import { ButtonSecondary } from "./Button/Button";

export const ErrorBoundaryFallback: React.FC = () => {
  return (
    <section className="relative bg-white py-24 md:py-44 lg:flex lg:h-screen lg:py-0">
      <div className="container relative z-10 mx-auto px-4 lg:m-auto">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="mb-4 mt-2 font-display text-4xl font-bold md:text-5xl">
            An error occurred
          </h2>
          <p className="mb-12 text-lg text-slate-500 md:text-xl">
            We are sorry if the website is broken. You can go back to the home
            page or contact us if it happens again.
          </p>
          <div className="flex flex-wrap justify-center gap-2">
            <a href="/">
              <ButtonSecondary size="sm">Go to the home page</ButtonSecondary>
            </a>
            <a href={getCrispUrl()} target="_blank">
              <ButtonSecondary size="sm">Contact us</ButtonSecondary>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
