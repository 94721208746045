import { connect, ConnectedProps } from "react-redux";
import { actions } from "../../../../redux/actions";
import { RootState } from "../../../../redux/store";

const mapState = (
  state: RootState,
  props: { view: "analytics" | "global" }
) => {
  const stats =
    props.view === "analytics" ? state.analytics.stats : state.spread.stats;

  const isFetching =
    props.view === "analytics"
      ? state.analytics.isFetching
      : state.spread.isFetching;

  return {
    stats,
    isFetching,
    orderBy: state.analytics.orderBy,
    websites: state.spread.websites,
    dimensions: state.analytics.dimensions,
    view: props.view,
  };
};

const mapDispatch = (dispatch: any) => ({
  onMount: () => {},
  onShow: () => {
    dispatch(actions.spread.$fetchOrConnect());
  },
  onDownload: () => {
    dispatch(actions.spread.$download());
  },
  onFilter(params: { from: string; to: string }) {
    dispatch(actions.spread.$SubmitCalendar(params));
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
