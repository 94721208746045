import { FormattedMessage } from "@/components/general/FormattedMessage/FormattedMessage";
import { ButtonSecondary } from "@/components/ui/Button/Button";
import { Modal, ModalHeader } from "@/components/ui/Modal/Modal";
import { PlusIcon } from "lucide-react";
import React from "react";

import { MODAL_KEYS } from "@/entities/ModalEntity";
import { useModal } from "@/hooks/use-modal";
import { AddMoreSearchConsoleAccountsConnectButton } from "../add-more-search-console-accounts-connect-button/add-more-search-console-accounts-connect-button";
import { AddMoreSearchConsoleAccountsList } from "../add-more-search-console-accounts-list/add-more-search-console-accounts-list";
import {
  connector,
  ContainerProps,
} from "./containers/add-more-search-console-accounts-modal.container";

export const Wrapper: React.FC<ContainerProps> = (props) => {
  const { isOpen } = useModal(MODAL_KEYS["add-more-search-consoles"]);

  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <div className="max-w-2xl">
        <ModalHeader
          icon={<PlusIcon className="h-6 w-6 text-orange-400" />}
          iconBackground="bg-orange-100"
          title={
            <FormattedMessage id="add-search-console-accounts/modal/title" />
          }
          description={
            <FormattedMessage id="add-search-console-accounts/modal/description" />
          }
        />

        <AddMoreSearchConsoleAccountsList />

        <div className="mt-4 justify-between md:flex">
          <AddMoreSearchConsoleAccountsConnectButton />

          <ButtonSecondary
            onClick={props.onClose}
            className="mt-4 w-full justify-center md:mt-0 md:w-auto"
          >
            <FormattedMessage id="add-search-console-accounts/modal/close" />
          </ButtonSecondary>
        </div>
      </div>
    </Modal>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const AddMoreSearchConsoleAccountsModal = connector(Container);
