import { Help } from "@/components/general/Help/Help";
import { Loader } from "@/components/general/Loader/Loader";
import { Container as C } from "@/components/ui/Container";
import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import {
  connector,
  ContainerProps,
} from "./containers/bing-auth-callback-page.container";

export const Wrapper: React.FC<ContainerProps> = (props) => {
  const { href } = useLocation();

  const url = new URL(href || "https://local.dev");

  useEffect(() => {
    props.onMount({
      code: url.searchParams.get("code") as string,
    });
  }, []);

  return (
    <C className="relative">
      <div className="fixed inset-0 z-40 flex h-full w-full items-center justify-center">
        <div className="text-center">
          <div className="font-display">Waiting for authentication…</div>
          <div className="font-display text-sm text-slate-500">
            If nothing happens, please contact me through the help center below.
          </div>
          <div className="relative mt-8">
            <Loader />
          </div>
        </div>
        <Help forceShow />
      </div>
    </C>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const BingAuthCallbackPage = connector(Container);
