import { MODAL_KEYS } from "@/entities/ModalEntity";
import { useLocation } from "@reach/router";

export const useModal = (key: keyof typeof MODAL_KEYS) => {
  const location = useLocation();
  const isOpen = location?.hash.includes(MODAL_KEYS[key]);
  const hashes = location?.hash.split("&");
  const get_actual_hash = hashes.find((hash) => hash.includes(key));
  const value = get_actual_hash?.split("=")[1] || null;

  return { isOpen, value };
};
