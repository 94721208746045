import { DevelopmentModule } from "@/modules/DevelopmentModule";
import { Modules } from "../../interfaces/IModule";
import * as types from "./types";

type DiState = Modules;

const initialState: DiState = new DevelopmentModule().build();

export function diReducer(
  state = initialState,
  action: types.DiActionTypes
): DiState {
  if (action.type === types.register) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
}
