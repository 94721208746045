import { RouteComponentProps, Router } from "@reach/router";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BingAuthenticateButton } from "./components/bing-authenticate-button/bing-authenticate-button";
import { CustomIntlProvider } from "./components/general/CustomIntlProvider/CustomIntlProvider";
import { ErrorBoundaryFallback } from "./components/ui/error-boundary-fallback";
import { Layout } from "./components/ui/layout";
import { ModuleProvider } from "./modules/ModuleProvider";
import AuthenticationBingCallbackRoute from "./pages/authentication/bing/callback";
import AuthenticationGoogleCallbackRoute from "./pages/authentication/google/callback";
import AuthenticationGoogleCallbackAddMoreSearchConsolesRoute from "./pages/authentication/google/callback-add-more-search-consoles";
import AuthenticationGoogleScopeNotFoundRoute from "./pages/authentication/google/scope-not-found";
import { init } from "./redux/store";
import { AnalyticsRoute } from "./routes/analytics";
import { HomeRoute } from "./routes/home";
import { LoginRoute } from "./routes/login";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
});

const TmpBing: React.FC<RouteComponentProps> = () => {
  return (
    <div>
      <BingAuthenticateButton />
    </div>
  );
};

const module = new ModuleProvider().build();

const { store } = init({}, module);

function App() {
  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryFallback />}
      onError={(error) => Sentry.captureException(error)}
    >
      <Provider store={store}>
        <CustomIntlProvider>
          <Layout>
            <Router>
              <HomeRoute path="/" />
              <AnalyticsRoute path="/analytics/:websiteId" />
              <LoginRoute path="/login" />
              <AuthenticationGoogleCallbackRoute path="/authentication/google/callback" />
              <AuthenticationGoogleCallbackAddMoreSearchConsolesRoute path="/authentication/google/callback-add-search-consoles" />
              <AuthenticationGoogleScopeNotFoundRoute path="/authentication/google/scope-not-found" />
              <AuthenticationBingCallbackRoute path="/authentication/bing/callback" />
              <TmpBing path="/authentication/bing/add-account" />
            </Router>
          </Layout>
        </CustomIntlProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
