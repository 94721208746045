import { UserEntity } from "@my-search-console/interfaces";

export const storeUser = "AUTH_STORE_USER";
export interface storeUserAction {
  type: typeof storeUser;
  payload: UserEntity;
}

export const AuthLogout = "AuthLogout";
export interface AuthLogoutAction {
  type: typeof AuthLogout;
}

export const setFetching = "AUTH_SET_FETCHING";
export interface setFetchingAction {
  type: typeof setFetching;
  payload: { value: boolean };
}

export const AuthSetInitialized = "AuthSetInitialized";
export interface AuthSetInitializedAction {
  type: typeof AuthSetInitialized;
  payload: { value: boolean };
}

export const AuthStoreSources = "AuthStoreSources";
export interface AuthStoreSourcesAction {
  type: typeof AuthStoreSources;
  payload: { google: boolean; yandex: boolean; bing: boolean };
}

export const AuthSetGoogleSearchConsoles = "AuthSetGoogleSearchConsoles";
export interface AuthSetGoogleSearchConsolesAction {
  type: typeof AuthSetGoogleSearchConsoles;
  payload: any[];
}

export const auth_bing_store_accounts = "auth_bing_store_accounts";
export interface auth_bing_store_accounts_action {
  type: typeof auth_bing_store_accounts;
  payload: any[];
}

export const auth_bing_accounts_fetching = "auth_bing_accounts_fetching";
export interface auth_bing_accounts_fetching_action {
  type: typeof auth_bing_accounts_fetching;
  payload: { value: boolean };
}

export type AuthActionTypes =
  | storeUserAction
  | setFetchingAction
  | AuthLogoutAction
  | AuthSetInitializedAction
  | AuthStoreSourcesAction
  | auth_bing_store_accounts_action
  | auth_bing_accounts_fetching_action
  | AuthSetGoogleSearchConsolesAction;
