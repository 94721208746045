import { actions } from "@/redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../redux/store";

const mapState = (state: RootState) => ({});

const mapDispatch = (dispatch: any) => ({
  onLogin: () => dispatch(actions.auth.$authenticate()),
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
