import { FormattedMessage } from "@/components/general/FormattedMessage/FormattedMessage";
import { ButtonSecondary } from "@/components/ui/Button/Button";
import { navigate } from "@reach/router";
import { ChevronDownIcon, GlobeIcon } from "lucide-react";
import { useEffect } from "react";
import {
  connector,
  ContainerProps,
} from "./containers/add-more-search-console-accounts-open-modal-button.container";

export const Wrapper: React.FC<ContainerProps> = (props) => {
  useEffect(() => {
    props.onMount();
  }, []);

  return (
    <>
      <ButtonSecondary
        size="sm"
        className="w-full justify-center md:w-auto md:justify-start"
        onClick={() =>
          navigate(
            props.isAuthenticated
              ? "#add-more-search-consoles"
              : "#should-login"
          )
        }
      >
        <GlobeIcon className="mr-1 h-4 w-4" />
        <FormattedMessage id="add-search-console-accounts/open-modal-button" />
        <ChevronDownIcon className="ml-1 h-4 w-4" />
      </ButtonSecondary>
    </>
  );
};

export const AddMoreSearchConsoleAccountsOpenModalButton = connector(Wrapper);
