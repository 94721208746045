import { Loader } from "@/components/general/Loader/Loader";
import { ChartContent } from "@/components/shared/chart/chart-content";
import React from "react";
import {
  RankingOrderByType,
  RankingStatsForFrontend,
} from "../../../entities/RankingWebsiteEntity";
import { GlobalStats } from "../GlobalStats/GlobalStats";
import { connector, ContainerProps } from "./containers/GeneralChart.container";

type Props = {
  onMount: () => void;
  stats: RankingStatsForFrontend;
  isFetching: boolean;
  orderBy: RankingOrderByType;
  onFilter: (date: string) => void;
};

const Wrapper: React.FC<Props> = (props) => {
  return (
    <div className="relative w-full rounded-lg border border-slate-100 bg-white p-2 sm:p-4">
      <div className="relative">
        {props.isFetching && <Loader />}
        <GlobalStats type="analytics" />
        <ChartContent view="analytics" />
      </div>
    </div>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const GeneralChart = connector(Container);
