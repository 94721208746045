import { RouteComponentProps } from "@reach/router";
import React from "react";
import { AuthCallbackSendCode } from "../../../../components/general/AuthCallbackSendCode/AuthCallbackSendCode";

const AuthenticationGoogleCallbackRoute: React.FC<RouteComponentProps> = (
  props
) => {
  return <AuthCallbackSendCode />;
};

export default AuthenticationGoogleCallbackRoute;
