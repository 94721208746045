import { Menu, Transition } from "@headlessui/react";
import {
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  ArrowTrendingDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { useLocation } from "@reach/router";
import classNames from "classnames";
import clsx from "clsx";
import {
  ArrowUpDownIcon,
  EyeIcon,
  MousePointerClickIcon,
  PercentIcon,
} from "lucide-react";
import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import {
  RankingOrderByType,
  RankingStatEntity,
} from "../../../../entities/RankingWebsiteEntity";
import {
  bigNumberFormatter,
  universalFormatNumber,
} from "../../../../utils/bigNumberFormatter";
import { formatUrl } from "../../../../utils/formatUrl";
import { getFiltersFromUrl } from "../../../../utils/getFiltersFromUrl";
import {
  setArrowIcon,
  setEvolutionColor,
} from "../../../../utils/setEvolution";
import { FormattedMessage } from "../../../general/FormattedMessage/FormattedMessage";
import { Loader } from "../../../general/Loader/Loader";
import { Tooltip } from "../../../ui/Tooltip";
import { MenuExportItem } from "./MenuExportItem";
import { MenuFilterItem } from "./MenuFilterItem";

function addRelativePercentage(
  data: RankingStatEntity[],
  type: RankingOrderByType,
  trendingDown?: boolean,
  href?: string
): Array<RankingStatEntity & { percentage: number }> {
  const filters = getFiltersFromUrl({ url: href || "" });
  const calculateDifference = (item: RankingStatEntity) => {
    return item[`previous_${type}`] - item[type];
  };

  const total = data.reduce((accumularor, value) => {
    if (trendingDown) {
      return calculateDifference(value);
    }

    return accumularor + value[type];
  }, 0);

  const orderBy = filters.orderBy ?? "clicks";

  const calculatePercentage = (item: RankingStatEntity) => {
    if (trendingDown) {
      return (calculateDifference(item) / total) * 100;
    }

    return (item[orderBy] / total) * 100;
  };

  const relative = [...data].map((item) => ({
    ...item,
    percentage: calculatePercentage(item),
  }));

  // set biggest relative percentage to 100% and the others relatively
  let biggestRelativePercentage = 0;
  relative.forEach((item) => {
    if (item.percentage > biggestRelativePercentage) {
      biggestRelativePercentage = item.percentage;
    }
  });

  relative.forEach((item) => {
    item.percentage = (item.percentage / biggestRelativePercentage) * 100;
  });

  return relative;
}

export const Histogram: React.FC<{
  type: "device" | "query" | "country" | "source" | "page";
  data: Array<RankingStatEntity>;
  highlights: RankingStatEntity[];
  hideActions?: boolean;
  label: string;
  color: "orange" | "blue" | "pink" | "green" | "slate";
  isFetching: boolean;
  view: RankingOrderByType;
  fluid?: boolean;
  dimensions: Array<
    "clicks" | "impressions" | "position" | "click_through_rate"
  >;
  onExportImage: () => void;
  onShowMore: () => void;
  onChangeView: (view: RankingOrderByType) => void;
  onClick: (value: string) => void;
  onExportData: (type: "csv" | "json") => void;
}> = (props) => {
  const { locale } = useIntl();
  const { href } = useLocation();
  const [showTrendingDown, setShowTrendingDown] = useState(false);

  return (
    <div
      id={props.type}
      className={classNames(
        "relative min-h-[200px] w-full rounded-lg border border-slate-100 bg-white p-4 font-display",
        props.fluid && "col-span-full"
      )}
    >
      {props.isFetching && <Loader></Loader>}

      <div className="flex items-center justify-between">
        <div className="font-display font-medium">
          <FormattedMessage id={`analytics/histogram/${props.type}`} />
        </div>

        <div className="flex items-center">
          {!props.hideActions && (
            <button
              onClick={() => setShowTrendingDown(!showTrendingDown)}
              className={clsx(
                "-mt-1 mr-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-pink-100 hover:text-pink-500",
                showTrendingDown && "bg-pink-100 text-pink-500"
              )}
            >
              <ArrowTrendingDownIcon className="h-4 w-4" />
            </button>
          )}
          {!props.hideActions && (
            <button
              onClick={props.onShowMore}
              className="-mt-1 mr-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-pink-100 hover:text-pink-500"
            >
              <ArrowsPointingOutIcon className="h-4 w-4" />
            </button>
          )}
          <Menu as="div" className="relative">
            <Menu.Button className="-mr-1 -mt-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-pink-100 hover:text-pink-500">
              <EllipsisHorizontalIcon className="h-5 w-5" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white font-display font-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuFilterItem
                    onChangeView={props.onChangeView}
                    type="clicks"
                  />
                  <MenuFilterItem
                    onChangeView={props.onChangeView}
                    type="position"
                  />
                  <MenuFilterItem
                    onChangeView={props.onChangeView}
                    type="impressions"
                  />
                  <MenuFilterItem
                    onChangeView={props.onChangeView}
                    type="click_through_rate"
                  />

                  <div className="mb-1 h-1 border-b border-slate-100" />

                  <MenuExportItem onClick={props.onExportImage} type="png" />
                  <MenuExportItem
                    onClick={() => props.onExportData("csv")}
                    type="csv"
                  />
                  <MenuExportItem
                    onClick={() => props.onExportData("json")}
                    type="json"
                  />
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <table className="mt-2 w-full table-fixed">
        <thead>
          <tr className="text-left text-sm text-slate-500">
            <th className="font-medium">
              <FormattedMessage
                id={`analytics/histogram/legend/${props.type}`}
              />
            </th>
            {props.dimensions.map((dimension) => (
              <th
                key={dimension}
                onClick={() => props.onChangeView(dimension)}
                className={clsx(
                  "w-16 cursor-pointer border-b-2 px-2 pb-1 text-center",
                  props.view !== dimension &&
                    "border-slate-100 hover:border-slate-200",
                  props.view === dimension &&
                    dimension === "position" &&
                    "border-position text-position",
                  props.view === dimension &&
                    dimension === "click_through_rate" &&
                    "border-click_through_rate text-click_through_rate",
                  props.view === dimension &&
                    dimension === "clicks" &&
                    "border-clicks text-clicks",
                  props.view === dimension &&
                    dimension === "impressions" &&
                    "border-impressions text-impressions"
                )}
              >
                <div className={clsx("inline-flex items-center")}>
                  {dimension === "clicks" && (
                    <MousePointerClickIcon className="h-4 w-4" />
                  )}
                  {dimension === "impressions" && (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  {dimension === "position" && (
                    <ArrowUpDownIcon className="h-4 w-4" />
                  )}
                  {dimension === "click_through_rate" && (
                    <PercentIcon className="h-4 w-4" />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {addRelativePercentage(
            showTrendingDown ? props.highlights : props.data,
            props.view,
            showTrendingDown,
            href
          ).map((item, index) => (
            <tr key={index} className="">
              <td
                className={clsx(
                  props.dimensions.length <= 2 && "w-3/4",
                  props.dimensions.length === 3 && "w-1/2",
                  props.dimensions.length === 4 && "w-1/2"
                )}
              >
                <div className="relative mb-0.5 flex flex-grow items-center p-2">
                  <div
                    className={classNames(
                      "absolute left-0 top-0 block h-full rounded-lg",
                      props.color === "orange" && "bg-orange-50",
                      props.color === "pink" && "bg-pink-100",
                      props.color === "blue" && "bg-blue-50",
                      props.color === "green" && "bg-emerald-50",
                      props.color === "slate" && "bg-slate-50"
                    )}
                    style={{ width: item.percentage + "%" }}
                  ></div>
                  <span
                    className="relative cursor-pointer font-display text-sm font-medium text-slate-900 hover:underline"
                    onClick={() => props.onClick(item[props.label])}
                  >
                    {props.type === "country" && (
                      // @ts-ignore
                      <FormattedMessage id={`country/${item[props.label]}`} />
                    )}
                    {props.type === "page" && (
                      <div className="flex items-center">
                        <span>{formatUrl(item[props.label])}</span>
                        <a
                          href={item[props.label]}
                          className="ml-2"
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                        </a>
                      </div>
                    )}
                    {props.type !== "country" &&
                      props.type !== "page" &&
                      item[props.label]}
                  </span>
                </div>
              </td>

              {props.dimensions.map((dimension, index) => (
                <td
                  key={dimension}
                  className={clsx(
                    "border-l border-dashed border-slate-100 text-center",
                    index === 0 && "!border-transparent"
                  )}
                >
                  <Tooltip
                    direction="left"
                    align="left"
                    label={
                      <p className="flex items-center font-display text-base font-medium">
                        <span className="text-slate-400">
                          {universalFormatNumber({
                            num: item[`previous_` + dimension],
                            locale,
                          })}
                        </span>
                        <span
                          className={classNames(
                            setEvolutionColor({
                              type: dimension,
                              previous: item[`previous_${dimension}`],
                              current: item[dimension],
                            }),
                            "px-[2px]"
                          )}
                        >
                          {setArrowIcon({
                            type: dimension,
                            previous: item[`previous_${dimension}`],
                            current: item[dimension],
                          })}
                        </span>
                        <span>
                          {universalFormatNumber({
                            num: item[dimension],
                            locale,
                          })}
                        </span>
                      </p>
                    }
                  >
                    <div className="text-center font-display font-medium">
                      <div
                        className={clsx(
                          "leading-0 flex items-center justify-end",
                          setEvolutionColor({
                            type: dimension,
                            previous: item[`previous_${dimension}`],
                            current: item[dimension],
                          })
                        )}
                      >
                        <span>{bigNumberFormatter(item[dimension], 1)}</span>
                      </div>
                      <div className="leading-0 -mt-2 text-center text-xs text-slate-400">
                        {bigNumberFormatter(item[`previous_${dimension}`], 1)}
                      </div>
                    </div>
                  </Tooltip>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
