import { AnalyticsCalendarModal } from "@/components/analytics/AnalyticsCalendarModal/AnalyticsCalendarModal";
import { FilterBar } from "@/components/analytics/FilterBar/FilterBar";
import { GeneralChart } from "@/components/analytics/GeneralChart/GeneralChart";
import { HistogramModal } from "@/components/analytics/HistogramModal/HistogramModal";
import { HorizontalHistogram } from "@/components/analytics/HorizontalHistogram/HorizontalHistogram";
import { MobileNavbar } from "@/components/general/MobileNavbar/MobileNavbar";
import { Notifications } from "@/components/general/Notifications/Notifications";
import { Footer } from "@/components/marketing/Footer";
import { Container } from "@/components/ui/Container";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { Navbar } from "../components/general/Navbar/Navbar";

export const AnalyticsRoute: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <div className="relative">
        <Navbar />

        <Container>
          <div className="my-2">
            <FilterBar />

            <div className="relative">
              <div className="mt-2" />

              <GeneralChart />
              <HorizontalHistogram />
            </div>
          </div>
        </Container>
      </div>

      <AnalyticsCalendarModal />
      <MobileNavbar />
      <HistogramModal />
      <Footer />
      <Notifications />
    </>
  );
};
