import { useEffect } from "react";
import { Loader } from "../Loader/Loader";
import {
  connector,
  ContainerProps,
} from "./containers/login-trigger.container";

export const Wrapper: React.FC<ContainerProps> = (props) => {
  useEffect(() => {
    props.onLogin();
  }, []);

  return <Loader />;
};

export const LoginTrigger = connector(Wrapper);
