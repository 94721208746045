import { actions } from "@/redux/actions";
import { RootState } from "@/redux/store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: RootState) => ({});

const mapDispatch = (dispatch: any) => ({
  onMount: (params: { code: string }) => {
    dispatch(actions.auth.$BingWebmasterAccountsSendCallbackCode(params));
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
