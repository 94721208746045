import { FormattedMessage } from "@/components/general/FormattedMessage/FormattedMessage";
import { Tooltip } from "@/components/ui/Tooltip";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "lucide-react";
import React from "react";
import {
  connector,
  ContainerProps,
} from "./containers/add-more-bing-account-list.container";

const Wrapper: React.FC<ContainerProps> = (props) => {
  return (
    <div>
      <ul
        role="list"
        className="mt-4 divide-y divide-slate-200 rounded-lg border border-slate-200 px-4 py-2"
      >
        {props.bing_accounts.length === 0 && (
          <li className="mt-4 flex flex-col items-center justify-center rounded-lg p-4 py-4">
            <UserPlusIcon className="h-8 w-8 text-slate-400" />
            <p className="mt-4 font-display text-sm text-slate-500">
              <FormattedMessage id="add-search-console-accounts/list/no-account" />
            </p>
          </li>
        )}

        {props.bing_accounts.map((account: any) => (
          <li
            key={account.id}
            className="flex items-center justify-between space-x-3 py-4"
          >
            <div className="flex min-w-0 flex-1 items-center space-x-3">
              <div className="flex-shrink-0">
                <img
                  className="flex h-10 w-10  items-center justify-center rounded-full bg-slate-50 text-slate-400"
                  src={account.owner_of_the_search_console.picture}
                />
              </div>
              <div className="min-w-0 flex-1">
                <p className="truncate font-display text-base font-semibold  text-slate-900">
                  {account.owner_of_the_search_console.email}
                </p>
              </div>
            </div>

            <div className="flex-shrink-0">
              <Tooltip
                direction="left"
                label={
                  <FormattedMessage id="add-search-console-accounts/list/delete" />
                }
              >
                <button
                  type="button"
                  onClick={() => props.onDelete(account.id)}
                  className="inline-flex items-center rounded-full p-2 text-sm font-semibold leading-6 text-slate-400 transition-all duration-300 ease-in-out hover:bg-red-50 hover:text-red-400"
                >
                  <TrashIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </Tooltip>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const AddMoreBingAccountList = connector(Container);
