import { MODAL_KEYS } from "@/entities/ModalEntity";
import { actions } from "@/redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../redux/store";

const mapState = (state: RootState) => ({});

const mapDispatch = (dispatch: any) => ({
  onClose: () => {
    dispatch(
      actions.modals.$close({ key: MODAL_KEYS["add-more-search-consoles"] })
    );
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
