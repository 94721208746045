import { AuthCallbackSendCodeAddSearchConsole } from "@/components/general/AuthCallbackSendCodeAddSearchConsole/AuthCallbackSendCodeAddSearchConsole";
import { RouteComponentProps } from "@reach/router";
import React from "react";

const AuthenticationGoogleCallbackAddMoreSearchConsolesRoute: React.FC<
  RouteComponentProps
> = (props) => {
  return <AuthCallbackSendCodeAddSearchConsole />;
};

export default AuthenticationGoogleCallbackAddMoreSearchConsolesRoute;
