import { FormattedMessage } from "@/components/general/FormattedMessage/FormattedMessage";
import { ButtonPrimary } from "@/components/ui/Button/Button";
import { Plus } from "lucide-react";
import React from "react";
import {
  connector,
  ContainerProps,
} from "./containers/add-more-search-console-accounts-connect-button.container";

type Props = {
  onAdd: () => void;
};

const Wrapper: React.FC<Props> = (props) => {
  return (
    <ButtonPrimary
      onClick={props.onAdd}
      className="w-full justify-center md:w-auto"
    >
      <Plus className="mr-1 h-6 w-6" />
      <FormattedMessage id="add-search-console-accounts/modal/button-add" />
    </ButtonPrimary>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const AddMoreSearchConsoleAccountsConnectButton = connector(Container);
