import { LoginTrigger } from "@/components/general/login-trigger/login-trigger";
import { RouteComponentProps } from "@reach/router";

export const LoginRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <>
      <LoginTrigger />
    </>
  );
};
