import {
  ArrowDownRightIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import clsx from "clsx";
import {
  EyeIcon,
  ListOrderedIcon,
  MousePointerClickIcon,
  PercentIcon,
} from "lucide-react";
import React from "react";
import { useIntl } from "react-intl";
import {
  bigNumberFormatter,
  universalFormatNumber,
} from "../../../utils/bigNumberFormatter";
import { setArrowIcon, setEvolutionColor } from "../../../utils/setEvolution";
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage";
import { Tooltip } from "../../ui/Tooltip";
import { connector, ContainerProps } from "./containers/GlobalStats.container";

function isDecimal(n: number) {
  return n % 1 !== 0;
}

const formatNumber = (value: number, style?: string) => {
  if (isDecimal(value) && style === "percent") {
    return bigNumberFormatter(value, 2) + "%";
  }
  if (isDecimal(value)) {
    return bigNumberFormatter(value, 2);
  }
  return value?.toLocaleString("en-US");
};

function percentEvolution(params: {
  type: string;
  before: number;
  after: number;
}) {
  if (params.type === "position") {
    return ((params.before - params.after) / params.before) * 100;
  }
  return ((params.after - params.before) / params.before) * 100;
}

const Item: React.FC<{
  icon: React.ReactNode;
  value: number;
  before: number;
  style?: string;
  type: "clicks" | "impressions" | "position" | "click_through_rate";
  showEvolution?: boolean;
  selected: boolean;
  isFetching: boolean;
  onClick: () => void;
}> = (props) => {
  const { locale } = useIntl();
  const evolution = percentEvolution({
    type: props.type,
    before: props.before,
    after: props.value,
  });

  return (
    <Tooltip
      direction="bottom"
      label={
        <p className="flex items-center font-display text-base font-medium">
          {props.showEvolution && (
            <span className="text-slate-400">
              {universalFormatNumber({
                num: props.before,
                locale,
              })}
            </span>
          )}
          {props.showEvolution && (
            <span
              className={classNames(
                setEvolutionColor({
                  type: props.type,
                  previous: props.before,
                  current: props.value,
                }),
                "px-[2px]"
              )}
            >
              {setArrowIcon({
                type: props.type,
                previous: props.before,
                current: props.value,
              })}
            </span>
          )}
          <span>{universalFormatNumber({ num: props.value, locale })}</span>
        </p>
      }
      align="center"
      className="flex-shrink-0"
    >
      <button
        className={classNames(
          "group relative flex h-16 w-full items-center overflow-hidden border-b-2 border-transparent  px-2 hover:border-slate-200",
          props.selected && props.type === "position" && "!border-position",
          props.selected &&
            props.type === "click_through_rate" &&
            "!border-click_through_rate",
          props.selected && props.type === "clicks" && "!border-clicks",
          props.selected &&
            props.type === "impressions" &&
            "!border-impressions"
        )}
        onClick={props.onClick}
      >
        <div className={clsx("w-full ")}>
          <dt
            className={clsx(
              "flex items-center gap-1 text-nowrap text-xs font-medium leading-tight",
              props.type === "clicks" && "text-clicks",
              props.type === "impressions" && "text-impressions",
              props.type === "position" && "text-position",
              props.type === "click_through_rate" && "text-click_through_rate"
            )}
          >
            {props.icon}
            <FormattedMessage id={`analytics/histogram/filter/${props.type}`} />
          </dt>
          <dd className="flex items-end ">
            <div className="flex items-center text-nowrap text-base font-medium text-slate-900 md:text-xl">
              {formatNumber(props.value, props.style)}
            </div>

            {props.showEvolution && (
              <div
                className={classNames(
                  evolution === 0 && " text-slate-500",
                  evolution > 0 && " text-emerald-500",
                  evolution < 0 && " text-red-500",
                  "inline-flex transform items-center text-nowrap rounded px-1 text-xs font-medium sm:px-2 md:text-sm"
                )}
              >
                {evolution === 0 && (
                  <ArrowRightIcon className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-slate-500 md:h-5 md:w-5" />
                )}
                {evolution > 0 && (
                  <ArrowUpRightIcon className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-emerald-500 md:h-5 md:w-5" />
                )}
                {evolution < 0 && (
                  <ArrowDownRightIcon className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500 md:h-5 md:w-5" />
                )}
                {props.before === 0 && "0"}
                {props.before !== 0 &&
                  bigNumberFormatter(Math.abs(evolution), 2)}{" "}
                %
              </div>
            )}
          </dd>
        </div>
      </button>
    </Tooltip>
  );
};

export const Wrapper: React.FC<ContainerProps> = (props) => {
  return (
    <div className="m-auto mb-8 mt-2 w-full font-display">
      <dl className="flex flex-nowrap items-center gap-2 overflow-auto md:gap-8 md:overflow-visible">
        <Item
          icon={<MousePointerClickIcon className="h-4 w-4" />}
          value={props.stats.clicks}
          before={props.stats.previous_clicks}
          showEvolution={props.type === "analytics"}
          type="clicks"
          isFetching={props.isFetching}
          selected={props.dimensions.clicks}
          onClick={() => props.onClick("clicks")}
        />
        <Item
          icon={<EyeIcon className="h-4 w-4" />}
          value={props.stats.impressions}
          before={props.stats.previous_impressions}
          type="impressions"
          showEvolution={props.type === "analytics"}
          isFetching={props.isFetching}
          selected={props.dimensions.impressions}
          onClick={() => props.onClick("impressions")}
        />
        <Item
          icon={<ListOrderedIcon className="h-4 w-4" />}
          value={props.stats.position}
          before={props.stats.previous_position}
          type="position"
          showEvolution={props.type === "analytics"}
          isFetching={props.isFetching}
          selected={props.dimensions.position}
          onClick={() => props.onClick("position")}
        />
        <Item
          icon={<PercentIcon className="h-4 w-4" />}
          value={props.stats.click_through_rate}
          before={props.stats.previous_click_through_rate}
          style="percent"
          showEvolution={props.type === "analytics"}
          isFetching={props.isFetching}
          selected={props.dimensions.click_through_rate}
          type="click_through_rate"
          onClick={() => props.onClick("click_through_rate")}
        />
      </dl>
    </div>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const GlobalStats = connector(Container);
