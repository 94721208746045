import { FC } from "react";
import { Seo } from "../general/Seo/Seo";

export const Layout: FC<{ children: React.ReactNode }> = (props) => {
  return (
    <main className="antialiased">
      <Seo
        title="My Search Console"
        description="My Search Console"
        lang="en"
        langUrls={[]}
      />
      {props.children}
    </main>
  );
};
