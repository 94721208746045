import { RootState } from "@/redux/store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: RootState) => ({
  bing_accounts: state.auth.bing_accounts,
});

const mapDispatch = (dispatch: any) => ({
  onDelete: (id: string) => {
    // dispatch(actions.auth.$BingAccountsDelete({ id }));
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
