import { actions } from "@/redux/actions";
import { RootState } from "@/redux/store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: RootState) => ({
  is_authenticated: state.auth.authenticated,
  bing_accounts: state.auth.bing_accounts,
  bing_accounts_fetching: state.auth.bing_accounts_fetching,
});

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.auth.$bing_accounts_fetch());
  },
  onClick: () => {
    dispatch(actions.auth.$bing_open_add_modal());
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
