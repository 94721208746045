import { localStorageKeys } from "@/constants/localStorageKeys";
import { useLocation } from "@reach/router";
import React from "react";
import languages from "../../../constants/languages.json";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Modal } from "../../ui/Modal/Modal";
import {
  connector,
  ContainerProps,
} from "./containers/SelectLanguageModal.containers";

export const Wrapper: React.FC = () => {
  const location = useLocation();

  const isOpen = location.href?.includes("change-lang-modal=open") || false;
  const onSelect = (lang: string) => {
    localStorage.setItem(localStorageKeys.USER_LANGUAGE, lang);
    window.location.href = window.location.pathname;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => window?.history.back()}>
      <div className="flex max-w-md flex-wrap gap-4">
        {languages.map((lang) => (
          <button
            onClick={() => onSelect(lang.id)}
            key={lang.id}
            className="w-1/4 grow cursor-pointer rounded bg-slate-50 py-5 font-display font-medium outline-none transition-all duration-300 ease-in-out hover:border-pink-100 hover:bg-pink-50 hover:text-pink-400 focus:ring-2"
          >
            <img
              className="mx-auto block h-7 w-7 rounded-full"
              src={`/flags/${lang.id}.svg`}
            />
            <div className="mt-2 px-2 text-center">{lang.label}</div>
          </button>
        ))}
      </div>

      <div className="mt-4">
        <ButtonSecondary onClick={() => window?.history.back()} fullWidth>
          Close
        </ButtonSecondary>
      </div>
    </Modal>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const SelectLanguageModal = connector(Container);
