import { GlobalStats } from "@/components/analytics/GlobalStats/GlobalStats";
import { Loader } from "@/components/general/Loader/Loader";
import React from "react";
import { Card } from "../../ui/card";
import { ChartContent } from "./chart-content";
import { connector, ContainerProps } from "./containers/chart.container";

const Wrapper: React.FC<ContainerProps> = (props) => {
  return (
    <Card className="relative border border-slate-100 shadow-slate-100 transition-shadow ease-in-out">
      {props.isFetching && <Loader />}

      <div className="p-4">
        <GlobalStats type="global" />
      </div>

      <div className="mt-4" />

      <ChartContent view={props.view} />
    </Card>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const Chart = connector(Container);
