import { connect, ConnectedProps } from "react-redux";
import { actions } from "../../../../redux/actions";
import { RootState } from "../../../../redux/store";

const mapState = (
  state: RootState,
  props: { type: "analytics" | "global" }
) => {
  const stats =
    props.type === "analytics"
      ? state.analytics.stats.global
      : state.spread.stats.global;

  return {
    stats,
    selected: state.analytics.orderBy,
    isFetching: state.analytics.isFetching,
    dimensions: state.analytics.dimensions,
    type: props.type,
  };
};

const mapDispatch = (dispatch: any) => ({
  onClick: (
    type: "clicks" | "impressions" | "position" | "click_through_rate"
  ) => {
    dispatch(actions.analytics.AnalyticsToggleDimension({ type }));
    // dispatch(actions.analytics.$RankingStoreOrderBy(type))
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
