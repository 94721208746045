import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

type Breadcrumb = { label: string; url: string };

const getBreadcrumb = (breadcrumbs: Breadcrumb[], endpoint: string) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map(({ label, url }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: label,
      item: `${endpoint}${url}`,
    })),
  };
};

export const Seo: React.FC<{
  title: string;
  description: string;
  image?: string;
  imageExternal?: boolean;
  lang: string;
  article?: boolean;
  breadcrumbs?: Array<Breadcrumb>;
  index?: boolean;
  langUrls: Array<{ lang: string; url: string; isDefault: boolean }>;
}> = ({ title, description, breadcrumbs, index = true, lang, langUrls }) => {
  const [loaded, setLoaded] = useState(false);
  const siteUrl = "/";
  const pathname = "/";

  const seo = {
    title,
    favicon: "/favicon.png",
    description: description,
    image: `https://api.my-search-console.com/og/?url=${siteUrl}${pathname}`,
    url: `/`,
  };

  const twitterUsername = "@KM_Marques";

  const defaultLangUrl = langUrls.find((lang) => lang.isDefault === true);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang }}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />
      {langUrls.map(({ lang, url }) => (
        <link rel="alternate" hrefLang={lang} href={siteUrl + url} key={lang} />
      ))}
      <link
        rel="alternate"
        hrefLang={"x-default"}
        href={siteUrl + defaultLangUrl?.url}
      />
      <meta name="theme-color" content="#fbcfe8" />
      <link rel="icon" href={seo.favicon} />
      <meta name="viewport" content="width=device-width, user-scalable=no" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {!index && <meta name="robots" content="noindex" />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      <script
        defer
        data-domain="dashboard.my-search-console.com"
        src="https://plausible.my-search-console.com/js/script.outbound-links.pageview-props.tagged-events.js"
      ></script>
    </Helmet>
  );
};
