import { actions } from "@/redux/actions";
import { RootState } from "@/redux/store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: RootState) => ({
  accounts: state.auth.googleSearchConsoles,
});

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.auth.$SearchConsolesFetch());
  },
  onDelete: (id: string) => {
    dispatch(actions.auth.$SearchConsolesDelete({ id }));
  },
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
