import { Loader } from "@/components/general/Loader/Loader";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./card";

export const Skeleton = () => {
  return (
    <Card className="relative overflow-hidden">
      <Loader />
      <CardHeader>
        <CardTitle className="h-6 w-3/4 animate-pulse rounded bg-transparent"></CardTitle>
        <CardDescription className="mt-2 h-4 w-1/2 animate-pulse rounded bg-transparent"></CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-32 w-full animate-pulse rounded bg-transparent"></div>
      </CardContent>
    </Card>
  );
};
