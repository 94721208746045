import { RouteComponentProps } from "@reach/router";
import React from "react";
import { ScopeNotFoundModal } from "../../../../components/general/ScopeNotFoundModal/ScopeNotFoundModal";

const AuthenticationGoogleScopeNotFoundRoute: React.FC<RouteComponentProps> = (
  props
) => {
  return <ScopeNotFoundModal />;
};

export default AuthenticationGoogleScopeNotFoundRoute;
