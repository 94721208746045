import { getCallbackUrl } from "@/constants/authentication";
import { GetUserInfoResponse } from "@/interfaces/IAuthRepository";
import { ApiService } from "@/services/ApiService";
import { ErrorEntity, UserEntity } from "@my-search-console/interfaces";

export class ApiAuthRepository {
  constructor(private apiService: ApiService) {}

  async getGoogleSearchAccounts() {
    const response = await this.apiService.get<any>(
      "/auth/google-search-accounts"
    );

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message };
    }

    return { error: false, body: response.data };
  }

  async get_bing_accounts() {
    const response = await this.apiService.get<any>("/auth/bing-accounts");

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message };
    }

    return { error: false, body: response.data };
  }

  async postAuthenticationCodeAddMoreSearchConsoles(params: {
    code: string;
    callbackUrl: string;
    language?: string;
  }) {
    try {
      const response = await this.apiService.post<{ access_token: string }>(
        `/auth/add-google-search-account`,
        {
          code: params.code || "bad-code",
          callbackUrl: params.callbackUrl,
          language: params.language,
        }
      );

      if (response.data.statusCode === 400) {
        return { error: true, code: response.data.message };
      }

      return { error: false, body: response.data.access_token };
    } catch (e: unknown) {
      // @ts-ignore
      return { error: true, code: e.message };
    }
  }

  async deleteGoogleSearchAccount(id: string) {
    const response = await this.apiService.delete(
      `/auth/google-search-accounts/${id}`,
      {}
    );

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message };
    }

    return { error: false, body: response.data };
  }

  async getAuthenticationUrl(
    type: "google" | "yandex" | "bing",
    callbackUrl?: string
  ): Promise<
    { error: true; code: ErrorEntity } | { error: false; body: string }
  > {
    const response = await this.apiService.get<{ url: string }>(
      `/auth/${type}/url?callback=${callbackUrl || getCallbackUrl(type)}`
    );

    if (response.data.statusCode === 400) {
      return { error: true, code: response.data.message };
    }

    return { error: false, body: response.data.url };
  }

  async postAuthenticationCode(params: {
    code: string;
    callbackUrl: string;
    type: "google" | "yandex" | "bing";
    language?: string;
  }): Promise<
    | {
        error: true;
        code: ErrorEntity;
      }
    | {
        error: false;
        body: string;
      }
  > {
    try {
      const response = await this.apiService.post<{ access_token: string }>(
        `/auth/${params.type}/callback`,
        {
          code: params.code || "bad-code",
          callbackUrl: params.callbackUrl,
          language: params.language,
        }
      );

      if (response.data.statusCode === 400) {
        return { error: true, code: response.data.message };
      }

      return { error: false, body: response.data.access_token };
    } catch (e) {
      // @ts-ignore
      return { error: true, code: e.message };
    }
  }

  async getUserInfo(): Promise<GetUserInfoResponse> {
    const response = await this.apiService.get<UserEntity>("/auth/profile");

    if (response.data.statusCode === 400)
      return {
        error: true,
        code: response.data.message,
      };

    return {
      error: false,
      body: {
        ...response.data,
        created_at: new Date(response.data.created_at),
      },
    };
  }
}
