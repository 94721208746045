import { AddMoreBingAccountModal } from "@/components/add-more-bing-account/add-more-bing-account-modal/add-more-bing-account-modal";
import { AddMoreBingAccountOpenModalButton } from "@/components/add-more-bing-account/add-more-bing-account-open-modal-button/add-more-bing-account-open-modal-button";
import { AddMoreSearchConsoleAccountsModal } from "@/components/add-more-search-console-accounts/add-more-search-console-accounts-modal/add-more-search-console-accounts-modal";
import { AddMoreSearchConsoleAccountsOpenModalButton } from "@/components/add-more-search-console-accounts/add-more-search-console-accounts-open-modal-button/add-more-search-console-accounts-open-modal-button";
import { MODAL_KEYS } from "@/entities/ModalEntity";
import { useModal } from "@/hooks/use-modal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useLocation } from "@reach/router";
import React from "react";
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage";
import { DateSelector } from "./components/DateSelector/DateSelector";
import { connector, ContainerProps } from "./containers/FilterBar.container";

function getFiltersFromUrl(href: string) {
  const url = new URL(href);
  const query = url.searchParams.get("query");
  const device = url.searchParams.get("device");
  const country = url.searchParams.get("country");
  const source = url.searchParams.get("source");
  const page = url.searchParams.get("page");

  return [
    { type: "query", value: query },
    { type: "country", value: country },
    { type: "device", value: device },
    { type: "source", value: source },
    { type: "page", value: page },
  ].filter(({ value }) => !!value) as {
    type: "source" | "date" | "query" | "country" | "device" | "page";
    value: string;
  }[];
}

export const Wrapper: React.FC<ContainerProps> = (props) => {
  const { href } = useLocation();
  const { isOpen } = useModal(MODAL_KEYS["dev-mode"]);

  const filters = getFiltersFromUrl(href || "https://www.local.dev");

  return (
    <>
      <div className="">
        <div className="mb-4 w-full md:hidden">
          <AddMoreSearchConsoleAccountsOpenModalButton />
          {isOpen && <AddMoreBingAccountOpenModalButton />}
        </div>
        <div className="flex w-full items-center justify-center rounded-lg border border-slate-100 bg-white px-2 py-2">
          <div className="hidden md:block">
            <AddMoreSearchConsoleAccountsOpenModalButton />
            {isOpen && <AddMoreBingAccountOpenModalButton />}
          </div>

          <div className="ml-auto flex flex-shrink-0 items-center">
            <DateSelector />
          </div>
        </div>

        {filters.length > 0 && (
          <div className="no-scroll-bar sticky top-0 -z-10 mt-1 flex h-fit w-full items-center overflow-x-scroll rounded-md border border-slate-100 bg-white bg-opacity-80 py-2 backdrop-blur-sm transition-all md:hidden">
            <ul className="flex h-full items-center gap-1 whitespace-nowrap px-2 font-display">
              {filters.map(({ value, type }) => {
                return (
                  <li key={type}>
                    <button
                      type="button"
                      className="inline-flex h-10 items-center rounded-md bg-slate-50  px-4 pl-3 pr-1.5 font-display text-sm font-medium text-slate-900 transition duration-300 ease-in-out hover:bg-pink-100 hover:text-pink-500  focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                      onClick={() => props.onFilter({ value, type })}
                    >
                      <span>
                        {type === "country" ? (
                          // @ts-ignore
                          <FormattedMessage id={"country/" + value} />
                        ) : (
                          value
                        )}
                      </span>
                      <XMarkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      <AddMoreSearchConsoleAccountsModal />
      <AddMoreBingAccountModal />
    </>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const FilterBar = connector(Container);
