import clsx from "clsx";
import React, { useState } from "react";
import { Area, AreaChart, ReferenceArea, XAxis, YAxis } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useIsTouchDevice } from "@/utils/useIsTouchDevice";
import dayjs from "dayjs";
import { CardContent } from "../../ui/card";
import { connector, ContainerProps } from "./containers/chart.container";

const Wrapper: React.FC<ContainerProps> = (props) => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  const isTouchDevice = useIsTouchDevice();

  const chartData = props.stats.date.map((item) => ({
    day: item.date,
    clicks: item.clicks,
    previous_clicks: item.previous_clicks,
    impressions: item.impressions,
    previous_impressions: item.previous_impressions,
    position: item.position,
    previous_position: item.previous_position,
    click_through_rate: item.click_through_rate,
    previous_click_through_rate: item.previous_click_through_rate,
  }));

  const aspects = {
    analytics: "md:aspect-[16/2] aspect-[16/2]",
    global: "md:aspect-[16/2] aspect-[16/2]",
  };

  return (
    <CardContent className="relative p-0">
      <ChartContainer
        config={{}}
        className={clsx(aspects[props.view], "m-0 p-0")}
      >
        <AreaChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 0,
            right: 0,
            top: 4,
            bottom: 4,
          }}
          onMouseDown={(e) => {
            if (isTouchDevice) {
              return;
            }

            setFrom(e.activeLabel as string);
            setTo(dayjs(e.activeLabel).add(1, "day").format("YYYY-MM-DD"));
          }}
          onMouseMove={(e) => {
            if (isTouchDevice) {
              return;
            }

            if (from) setTo(e.activeLabel as string);
          }}
          onMouseUp={() => {
            if (isTouchDevice) {
              return;
            }

            if (from && to && from !== to) {
              const inverse = dayjs(from).isAfter(to);

              props.onFilter({
                from: inverse ? to : from,
                to: inverse ? from : to,
              });
            }

            setFrom(null);
            setTo(null);
          }}
        >
          {/* <CartesianGrid vertical={false} stroke="hsl(var(--border))" /> */}

          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent />}
            labelFormatter={(label) => {
              return dayjs(label).format("DD MMMM YYYY");
            }}
          />

          <XAxis
            dataKey="day"
            tickLine={false}
            axisLine={false}
            hide={true}
            interval={"preserveEnd"}
          />

          <YAxis
            yAxisId="clicks"
            stroke="var(--color-clicks)"
            tickLine={false}
            axisLine={false}
            hide={true}
            width={40}
            tickFormatter={(value) => {
              if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}k`;
              }
              return value;
            }}
            domain={["auto", "auto"]}
            interval={"preserveStartEnd"}
            orientation="left"
            tick={{ dx: 0 }}
            tickSize={0}
            mirror={true}
          />

          <YAxis
            yAxisId="impressions"
            orientation="right"
            stroke="var(--color-impressions)"
            allowDataOverflow={true}
            width={40}
            hide={true}
            mirror={true}
            domain={["auto", "auto"]}
            tickFormatter={(value) => {
              if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}k`;
              }
              return value;
            }}
            interval={"preserveStartEnd"}
          />

          <YAxis
            yAxisId="position"
            orientation="right"
            reversed={true}
            stroke="var(--color-position)"
            tickLine={false}
            axisLine={false}
            allowDataOverflow={true}
            width={40}
            hide={true}
          />

          <YAxis
            yAxisId="click_through_rate"
            orientation="right"
            reversed={true}
            stroke="var(--color-click_through_rate)"
            tickLine={false}
            axisLine={false}
            allowDataOverflow={true}
            width={40}
            hide={true}
          />

          <Area
            yAxisId="clicks"
            dataKey="clicks"
            fillOpacity={0}
            strokeWidth={2}
            type="monotone"
            stroke="hsl(var(--chart-clicks))"
            isAnimationActive={true}
            hide={!props.dimensions.clicks}
          />

          <Area
            yAxisId="impressions"
            dataKey="impressions"
            fillOpacity={0}
            strokeWidth={2}
            type="monotone"
            stroke="hsl(var(--chart-impressions))"
            isAnimationActive={true}
            hide={!props.dimensions.impressions}
          />

          <Area
            yAxisId="position"
            dataKey="position"
            fillOpacity={0}
            strokeWidth={2}
            type="monotone"
            stroke="hsl(var(--chart-position))"
            isAnimationActive={true}
            hide={!props.dimensions.position}
          />

          <Area
            yAxisId="click_through_rate"
            dataKey="click_through_rate"
            fillOpacity={0}
            strokeWidth={2}
            type="monotone"
            stroke="hsl(var(--chart-click_through_rate))"
            isAnimationActive={true}
            hide={!props.dimensions.click_through_rate}
          />

          {props.view === "analytics" && (
            <>
              <Area
                yAxisId="clicks"
                dataKey="previous_clicks"
                fillOpacity={0}
                strokeWidth={2}
                strokeOpacity={0.5}
                type="monotone"
                strokeDasharray="5 5"
                stroke="hsl(var(--chart-clicks))"
                isAnimationActive={true}
                hide={!props.dimensions.clicks}
              />

              <Area
                yAxisId="impressions"
                dataKey="previous_impressions"
                fillOpacity={0}
                strokeWidth={2}
                strokeOpacity={0.5}
                type="monotone"
                strokeDasharray="5 5"
                stroke="hsl(var(--chart-impressions))"
                isAnimationActive={true}
                hide={!props.dimensions.impressions}
              />

              <Area
                yAxisId="position"
                dataKey="previous_position"
                fillOpacity={0}
                strokeWidth={2}
                strokeOpacity={0.5}
                type="monotone"
                strokeDasharray="5 5"
                stroke="hsl(var(--chart-position))"
                isAnimationActive={true}
                hide={!props.dimensions.position}
              />

              <Area
                yAxisId="click_through_rate"
                dataKey="previous_click_through_rate"
                fillOpacity={0}
                strokeWidth={2}
                strokeOpacity={0.5}
                type="monotone"
                strokeDasharray="5 5"
                stroke="hsl(var(--chart-click_through_rate))"
                isAnimationActive={true}
                hide={!props.dimensions.click_through_rate}
              />
            </>
          )}

          {from && to && (
            <ReferenceArea
              yAxisId="clicks"
              x1={from}
              x2={to}
              strokeOpacity={0.3}
              stroke="hsl(var(--chart-clicks))"
              fill="hsl(var(--chart-clicks))"
              fillOpacity={0.1}
            />
          )}
        </AreaChart>
      </ChartContainer>
    </CardContent>
  );
};

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
);

export const ChartContent = connector(Container);
